.order-header {
	background-color: @theme-blue;
}

.order-actions {
	.js & {
		.min-maximizer {
			background-color: @theme-blue;
			text-shadow: 1px 1px 1px darken(@theme-blue, 5%);
		}
	}
}
