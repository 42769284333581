header {
	.nav-menu-opener {
		color: @theme-blue;
	}
}

/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	header {
		.nav-menu-opener {
			&:hover, &:focus {
				color: @white;
				background-color: darken(@theme-blue, 20%);
			}
		}
	}
}
