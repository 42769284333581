nav {
	&.nav-menu {
		.nav-header {
			.user-info {
				color: @theme-blue;
			}
		}
	}
}

@media only screen and (min-width : @tablet-width) {
	nav {
		&.nav-menu {
			.nav-header {
				background-color: @theme-blue;
				.user-info {
					color: @white;
				}
				.nav-menu-closer {
					&:hover, &:focus {
						background-color: darken(@theme-blue, 20%);
					}
				}
			}
			.nav-list {
				li {
					&.versions {
						.accord-content {
							a, p {
								.status {
									color: @theme-blue;
								}
							}
						}
					}
				}
			}
		}
	}
}

