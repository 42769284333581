.lb-wrapper {
	&.lb-rejectionModal {
		.lb-body-wrapper  {
			.lb-body {
				.info-wrapper {
					.btn {
						&.lb-submit {
							background-color: @theme-blue;
							text-shadow: 1px 1px 1px darken(@theme-blue, 10%);
							border-bottom: 6px solid darken(@theme-blue, 20%);
						}
					}
				}
			}
		}
	}
}

/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	.lb-wrapper {
		&.lb-rejectionModal {
			.lb-body-wrapper  {
				.lb-body {
					.info-wrapper {
						.btn {
							&.lb-submit {
								&:hover, &:focus {
									background-color: lighten(@theme-blue, 5%);
								}
								&:active {
									background-color: darken(@theme-blue, 5%);
								}
							}
						}
					}
				}
			}
		}
	}
}
