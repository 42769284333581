.lb-wrapper {
	&.lb-tcModal {
		.lb-body-wrapper  {
			.lb-body {
				.heading {
					background-color: @theme-blue;
				}
				.info-wrapper {
					.btn.lb-closer {
						background-color: @theme-blue;
						border-bottom-color: darken(@theme-blue, 20%);
						text-shadow: 1px 1px 1px darken(@theme-blue, 10%);
					}
				}
			}
		}
	}
}

/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	.lb-wrapper {
		&.lb-tcModal {
			.lb-body-wrapper  {
				.lb-body {
					.info-wrapper {
						.btn.lb-closer {
							&:hover, &:focus {
								background-color: lighten(@theme-blue, 5%);
							}
							&:active {
								background-color: darken(@theme-blue, 5%);
							}
						}
					}
				}
			}
		}
	}
}
