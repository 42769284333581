* {
	box-sizing: border-box;
}

body {
	background-color: @body-bg-color;
}

h1 {
	color: @h1-main-color;
}

h3 {
	color: @h3-main-color;
}
